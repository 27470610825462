import React, {useEffect} from 'react';
import Layout from '../components/layout';
import {Link, navigate} from 'gatsby';
// import err404 from '../assets/404err/404err.png';
import './404.scss';

const NotFoundPage = () => {
  const browser =
    typeof window !== 'undefined' &&
    global &&
    global.window &&
    global.window.location &&
    global.window.location.pathname &&
    !global.window.location.pathname.includes('hexomatic-pitch-deck') &&
    !global.window.location.pathname.includes('pitch-deck') &&
    !global.window.location.pathname.includes('affiliate');

  useEffect(() => {
    if (
      global &&
      global.window &&
      global.window.location &&
      global.window.location.pathname &&
      global.window.location.pathname.includes('pitch-deck')
    ) {
      window.location.href =
        'https://docs.google.com/presentation/d/1bQjsrnyjuQ1zVNXTaa9NjbKVfWEBPEmLAViFHkxfybY/edit';
    } else if (
      global &&
      global.window &&
      global.window.location &&
      global.window.location.pathname &&
      global.window.location.pathname.includes('affiliate')
    ) {
      window.location.href =
      'https://app.impact.com/campaign-promo-signup/Hexact.brand';
    }
    setTimeout(() => {
      navigate('/');
    }, 5000);
  }, []);

  return (
    browser && (
      <Layout className="page-not-found-container">
        <div className="page-not-found">
          <h3 className="page-title">Page not found</h3>
          <img src="https://storage.googleapis.com/hexact/404/404.webp" height='100%'/>
          <Link to="/" className="button-general">
            <span>Go to home page</span>
          </Link>
        </div>
      </Layout>
    )
  );
};
export default NotFoundPage;
